import React, { InputHTMLAttributes, TextareaHTMLAttributes, useState } from 'react';
import { FieldProps, LabelProps, InputProps } from '../common/field.types';
import { InputStyled } from '../common/field.styled';
import { getAriaDescribedby } from '../../helpers/misc';
import Label from '../common/label';
import ErrorFeedback from '../common/error';
import ButtonIcon from '../../global/buttonIcon/buttonIcon';
import Icon from '../../global/icon/icon';
import { SIZE_ICON } from '../../resources/vars';
import classnames from 'classnames';

interface InputGroupProps extends FieldProps, LabelProps, InputProps {
  /**
   * Adds a component to the end of the input.
   */
  component?: React.ReactNode;
  hideCharacterCount?: boolean;
  arabic?: boolean;
  customError?: JSX.Element;
}

const Input: React.FC<InputGroupProps & InputHTMLAttributes<any>> = React.forwardRef<HTMLInputElement, InputGroupProps>(
  (
    {
      id,
      label,
      type = 'text',
      hideLabel = false,
      error,
      hintText,
      icon,
      component,
      hideCharacterCount = false,
      site = 'en',
      customError,
      ...other
    }: InputGroupProps & InputHTMLAttributes<any>,
    ref
  ) => {
    const [show, setShow] = useState(false);

    const getInput = (
      <div style={{ height: '40px' }} className={classnames({ relative: type === 'search' })}>
        {icon && (
          <div className={'flex absolute inset-y-0 left-0'}>
            <Icon
              icon={icon}
              container={false}
              elementSize={SIZE_ICON['sm']}
              color={'primary'}
              className={'my-auto mx-3'}
            />
          </div>
        )}
        <InputStyled
          ref={ref}
          id={id}
          name={id}
          type={'password' === (type as any) && show ? 'text' : (type as any)}
          aria-describedby={getAriaDescribedby(id)}
          $hasIcon={!!icon}
          $hasComponent={!!component}
          {...(other as InputHTMLAttributes<any>)}
        />
        {component && <div className={'absolute flex inset-y-0 right-0'}>{component}</div>}
      </div>
    );

    return (
      <div>
        {label && (
          <Label
            label={label}
            hideLabel={hideLabel}
            id={id}
            required={'required' in other && (other.required || other.required === undefined)}
            hintText={
              !hideCharacterCount &&
              (other.maxLength ? ((other.value as string)?.length || 0) + '/' + other.maxLength : hintText)
            }
          />
        )}
        {'textarea' === type ? (
          <InputStyled
            ref={ref}
            as={'textarea'}
            id={id}
            name={id}
            aria-describedby={getAriaDescribedby(id)}
            $hasIcon={false}
            $hasComponent={false}
            {...(other as any)}
          />
        ) : 'password' === type ? (
          <div className={'relative'}>
            {getInput}
            <div className={`absolute flex inset-y-0 ${site === 'ar' ? 'left-0 ml-1' : 'right-0'}`}>
              <ButtonIcon
                onClick={() => setShow(!show)}
                label={show ? 'Hide password' : 'Show password'}
                icon={show ? 'Eye' : 'EyeSlash'}
                mainColor={show ? 'primary' : 'secondary'}
                size={'sm'}
                className={'my-auto mr-1'}
              />
            </div>
          </div>
        ) : (
          getInput
        )}
        {error && <ErrorFeedback id={id} error={error} customError={customError} />}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
