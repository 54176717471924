import connector from '../connector';
import { ApiResponse } from '../../@types/global';
import { School } from '../../@types/Entity/School';
import { User } from '../../@types/Entity/User';

export function sendRegistrationLinks(users: string[]) {
  return connector.post('api/admin/send-registration-links', { users });
}

export function getSchoolFromCoordinator(email: string) {
  return connector.get<ApiResponse<School>>(`api/admin/schools/${email}`);
}

export function postUser(data: CreateOrUpdateUserType) {
  return connector.post('/api/admin/users', data);
}

export function putUserInfo({ params, body }: { params: { id: string }; body: CreateOrUpdateUserType }) {
  return connector.put(`/api/admin/users/${params.id}`, body);
}

export function getSchoolMentors() {
  return connector.get<ApiResponse<User[]>>('/api/admin/users/mentors');
}

export interface CreateOrUpdateUserType {
  role: string;
  first_name: string;
  last_name: string;
  email: string;
  password?: string;
  organisation?: string;
  trn?: string | null;
  urn?: string | null;
  slots?: number | null;
  subscription?: { teachers: number };
  regions?: number[] | null;
  associate_colleges?: number[] | null;
  region?: number | null;
  school_associate_colleges?: number[] | null;
  is_great_teaching_lead?: boolean | null;
  is_mentor?: boolean | null;
  is_induction_lead?: boolean | null;
  is_associate_college?: boolean | null;
  is_regional_lead?: boolean | null;
  is_early_career_teacher?: boolean | null;
  is_niot?: boolean | null;
  role_description?: string | null;
  role_type?: string | null;
  subjects?: string[] | null;
  mentoring?: boolean | null;
  experience?: number | null;
  year?: null | string;
  qualifications?: string | null;
  associated_coordinator?: string;
  country?: string;
  timezone?: string;
  schools?: {
    id: string;
    name: string;
    slots: string;
    coordinator_name: string;
    coordinator_email: string;
  }[];
  category_1?: string;
  category_2?: string;
  category_3?: string;
  age_range?: string[];
}

export function downloadMontoringUsersCsv({
  order,
  filter,
  show,
  name,
  cohort,
  ac_10,
  ac_19,
  year,
}: {
  order: string;
  filter: string;
  show: string;
  name: string;
  cohort: number;
  ac_10?: boolean;
  ac_19?: boolean;
  year?: 0 | 1 | 2;
}) {
  return connector
    .get(`api/admin/users/monitoring/export`, {
      responseType: 'blob',
      params: { order, filter, show, cohort, ac_10, ac_19, year },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}


