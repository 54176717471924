import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { getEseUserDetail, postRegisterEseUser } from '../../service/api';
import AuthLayout from '../../layout/auth';
import Input from '../../form/input/input';
import Button from '../../global/button/button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FIRST_NAME_VALIDATION, LAST_NAME_VALIDATION, PASSWORD_VALIDATION } from '../../resources/schemas';
import ReCAPTCHA from 'react-google-recaptcha';
import Alert from '../../global/alert/alert';
import { LinkStyled } from '../../global/link/link.styled';
import { ESE_USERS } from '../../service/queryKeys';

const SITE = 'en';

const EseRegistrationEnglishSite = () => {
  const [searchValue, setSearchValue] = useState<string>('');

  const teachers = useQuery([ESE_USERS, searchValue], () => getEseUserDetail(searchValue), {
    select: (data) => data.data,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    placeholderData: [],
  });

  const registrationMutation = useMutation(postRegisterEseUser, {
    onSuccess: () => {
      window.location.href = `/ese-complete-registration?site=${SITE}`;
    },
  });

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowMessage(searchValue !== '');
    }, 12000);
  }, [searchValue]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        first_name: FIRST_NAME_VALIDATION,
        last_name: LAST_NAME_VALIDATION,
        password: PASSWORD_VALIDATION,
        confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
        school: Yup.string().trim().max(255).required(),
        oracle_erp: Yup.number().required(),

        captcha: Yup.string().required().nullable(),
      })
    ),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: '',
      school: '',
      oracle_erp: '',
      branch: '',
      captcha: null as string | null,
    },
  });

  useEffect(() => {
    if (!teachers?.data?.data) {
      return;
    }
    setValue('first_name', teachers?.data?.data?.first_name || '');
    setValue('last_name', teachers?.data?.data?.last_name || '');
    setValue('school', teachers?.data?.data?.school_name || '');
    setValue('oracle_erp', teachers?.data?.data?.erp || '');
    setValue('branch', teachers?.data?.data?.branch || '');
  }, [teachers]);

  return (
    <AuthLayout title={'Registration'} eseMode={true} niotMode={false}>
      <form
        className={'py-5'}
        onSubmit={handleSubmit((data) => {
          registrationMutation.mutate({
            body: {
              first_name: data.first_name!,
              last_name: data.last_name!,
              email: data.email,
              password: data.password,
              school: data.school,
              oracle_erp: data.oracle_erp,
              branch: data.branch,
              site: SITE,
              captcha: data.captcha!,
            },
          });
        })}
      >
        <Alert type="info" className={'field-mb'}>
          Create your account to access courses and begin your learning with Evidence Based Education (EBE). Kindly note
          that accounts can only be created with an ESE email address.
        </Alert>

        <div className={'field-mb grid grid-cols-1 gap-y-2'}>
          <Input
            {...register('email')}
            id="email"
            label={'Email'}
            placeholder={`Please enter your email`}
            required
            onChange={(event) => setSearchValue(event.target.value)}
            error={errors.email?.message}
          />
          <div className={'p-4'}>
            {searchValue !== '' && showMessage && !teachers?.data?.data && (
              <Alert type={'info'}>
                The email entered is not recognised by the database. Please check that it is entered correctly. If it is
                correct and still not recognised, please contact{' '}
                <LinkStyled href={'mailto:ese@evidencebased.education?subject=Subject'} target={'_blank'}>
                  email
                </LinkStyled>{' '}
                support.
              </Alert>
            )}
            {teachers?.data?.data?.registered && (
              <Alert type={'info'}>
                It seems there is already an account registered to this email address. If you already have an account,
                sign-in{' '}
                <LinkStyled href={'https://evidence-based-education.thinkific.com/users/sign_in'} target={'_blank'}>
                  here.
                </LinkStyled>{' '}
                Please{' '}
                <LinkStyled href={'mailto:ese@evidencebased.education?subject=Subject'} target={'_blank'}>
                  email
                </LinkStyled>{' '}
                support if you need assistance.
              </Alert>
            )}
          </div>
        </div>
        <div className={'mb-8 grid grid-cols-1 gap-y-2'}>
          <Input
            {...register('password')}
            id={'password'}
            type={'password'}
            label={'Please specify your password'}
            placeholder={'Enter your password'}
            required
            error={errors.password?.message}
          />
        </div>
        <div className={'field-mb grid grid-cols-1 gap-y-2'}>
          <Input
            {...register('confirm_password')}
            id="confirm_password"
            type={'password'}
            label="Confirm Password"
            placeholder={'Please repeat your password'}
            required
            error={errors.confirm_password?.message}
          />
        </div>
        <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
          <div className={'field-mb'}>
            <Input {...register('first_name')} id="first_name" label="First Name" required />
          </div>
          <div className={'field-mb'}>
            <Input {...register('last_name')} id="last_name" label="Last Name" required />
          </div>
        </div>
        <div className={'field-mb'}>
          <Input {...register('school')} id="school" label={'School'} disabled={true} required />
        </div>
        <div className={'mb-8'}>
          <Input {...register('oracle_erp')} id={'oracle_erp'} label={'Oracle-ERP'} disabled={true} required />
        </div>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
          onChange={(token) => setValue('captcha', token)}
          onErrored={() => setValue('captcha', null)}
          onExpired={() => setValue('captcha', null)}
        />
        <Button
          className={'block w-full mt-5'}
          type={'submit'}
          disabled={!teachers?.data?.data || teachers?.data?.data?.registered || postRegisterEseUser.isLoading}
        >
          Register
        </Button>
      </form>
    </AuthLayout>
  );
};

export default EseRegistrationEnglishSite;
