import React, { ReactNode } from 'react';
import FooterContent from '../pages/common/footer';
import GTT_NIOT_LOGO from '@app/asset/images/GTT_NIoT.png';
import ESE_LOGO from '@app/asset/images/ESE_Logo.png';

interface AuthLayoutProps {
  /**
   * Page title.
   */
  title: string;
  /**
   * Page description.
   */
  description?: ReactNode | null;
  /**
   * Page content.
   */
  children: any;
  /**
   * Different image for NioT users.
   */
  niotMode?: boolean;

  /**
   * ESE LOGO.
   */
  eseMode?: boolean;
  arabic?: boolean;
  site?: any;
  titleCentered?: boolean;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  title,
  description,
  children,
  niotMode = true,
  eseMode = false,
  site,
  titleCentered = false,
}: AuthLayoutProps) => {
  return (
    <div className={'flex flex-col h-full bg-opacity-50'}>
      <div className={'md:w-3/5 max-w-screen-md py-10 m-auto'}>
        <header className={'mb-5'}>
          <div className={`${niotMode ? 'w-3/4' : eseMode ? 'w-2/3' : 'w-1/3'} mx-auto`}>
            <img
              src={eseMode ? ESE_LOGO : GTT_NIOT_LOGO}
              alt={'Great Teaching Toolkit'}
              width={'100%'}
              height={'auto'}
            />
          </div>
        </header>
        <section className={'shadow'}>
          <main className={'block-container bg-white'}>
            {title && (
              <div className={`${site == 'ar' ? 'flex' : ''} border-b mb-5`}>
                <div className="flex w-full">
                  <h2 className={`${site == 'ar' ? 'ml-auto' : ''} mx-auto`}>{title}</h2>
                </div>
                {description && <div className={'mt-3'}>{description}</div>}
              </div>
            )}
            {children as any}
          </main>
          <footer className={'bg-secondary'}>
            <FooterContent showLogoAtCopyright={false} />
          </footer>
        </section>
      </div>
    </div>
  );
};

export default AuthLayout;
